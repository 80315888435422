// const Cookies = require("cookies-js")
import Cookies from "cookies-js"
const hasStorage = (typeof (Storage) !== "undefined")
if (!hasStorage) console.warn("persistent storage downgraded to cookie due to browser incompatibility.")



const _remove_from_cookie = (key) => {
    Cookies.expire(key)
}

const _remove_from_storage = (key) => {
    if (key in window.localStorage){
        delete window.localStorage[key]
    }
    if (key in window.sessionStorage){
        delete window.sessionStorage[key]
    }
}

const _set_storage = (key, obj) => {
    window.localStorage[key] = JSON.stringify(obj)
}

const _set_cookie = (key, obj, options) => {
    Cookies.set(key, JSON.stringify(obj), options)
}

const _set_session_storage = (key, obj) => {
    window.sessionStorage[key] = JSON.stringify(obj)
}

const _get_from_storage = (key) => {
    return JSON.parse(window.localStorage.getItem(key))
}

const _get_from_cookie = (key) => {
    return JSON.parse(Cookies.get(key) || null)
}

const _get_from_session_storage = (key) => {
    return JSON.parse(window.sessionStorage.getItem(key))
}



const get = (key) => {
    const value = hasStorage ?
        _get_from_storage(key) :
        _get_from_cookie(key)
    return value
}

const remove = (key) => {
    _remove_from_storage(key)
    _remove_from_cookie(key)
}

const getAll = () => {
    return {
        ...Cookies.get(),
        ...window.localStorage,
        ...window.sessionStorage
    }
}

const sessionGet = key => {
    const value = hasStorage ?
        _get_from_session_storage(key) :
        _get_from_cookie(key)
    return value
}

const set = (key, obj) => {
    if (hasStorage) {
        _set_storage(key, obj)
    } else {
        _set_cookie(key, obj, {})
    }
}

const timeoutGet = (key) => {
    return _get_from_cookie(key)
}

const timeoutSet = (key, obj, expirationInDays) => {
    let exiprationInSeconds = expirationInDays * 60 * 60 * 24
    _set_cookie(key, obj, { expires: exiprationInSeconds })
}

const sessionSet = (key, obj) => {
    if (hasStorage) {
        _set_session_storage(key, obj)
    } else {
        _set_cookie(key, obj, {})
    }
}



export default {
    get: get,
    getAll,
    set: set,
    remove: remove,
    timeoutGet: timeoutGet,
    timeoutSet: timeoutSet,
    sessionGet: sessionGet,
    sessionSet: sessionSet
}