/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("whatwg-fetch")
require("intersection-observer")
require('details-polyfill')

exports.onServiceWorkerUpdateReady = () => {
    window.location.reload()
  }