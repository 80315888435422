import { observable, action, computed } from "mobx";

class ReviewsStore {
    @observable reviews = null
    @observable error = null

    constructor(svc) {
        this._svc = svc
    }

    @action.bound
    init() {
        this._svc.getLastReviews()
            .then(action(data => this.reviews = data))
            .catch(action(err => {
                console.error(err)
                this.error = err
            }))
    }

    @computed
    get loaded() {
        return !!this.reviews || !!this.error
    }


}

export default ReviewsStore