import PersistentStorage from "./persistent-storage"

const permissionMap = {
    "necessary": "n",
    "functional": "f",
    "performance": "p",
    "targeting": "t",
}

const makeKey = (key, permission) => { 
    if (!permissionMap.hasOwnProperty(permission)){
        throw Error("makeKey expects a valid permission")
    }
    return `${permissionMap[permission]}_${key}`
 }

class PersistentStorageWithPolicy{

    constructor(policy){
        this.policy = policy
    }

    get = (key, permission) => {
        return PersistentStorage.get(makeKey(key, permission))
    }

    remove = (key, permission) => {
        return PersistentStorage.remove(makeKey(key, permission))
    }

    removeAll = (permission) => {
        const all = PersistentStorage.getAll()
        const keys = Object.keys(all).filter(k => k.startsWith(`${permissionMap[permission]}_fdly`))
        keys.forEach(k => PersistentStorage.remove(k));
    }

    sessionGet = (key, permission) => {
        return PersistentStorage.sessionGet(makeKey(key, permission))
    }

    set = (key, obj, permission) => {
        if (this.policy[permission]){
            PersistentStorage.set(makeKey(key, permission), obj)
            return true
        }else{
            return false
        }
    }

    timeoutGet = (key, permission) => {
        return PersistentStorage.timeoutGet(makeKey(key, permission))
    }
    
    timeoutSet = (key, obj, expirationInDays, permission) => {
        if (this.policy[permission]){
            PersistentStorage.timeoutSet(makeKey(key, permission), obj, expirationInDays)
            return true
        }else{
            return false
        }
    }
    
    sessionSet = (key, obj, permission) => {
        if (this.policy[permission]){
            PersistentStorage.sessionSet(makeKey(key, permission), obj)
            return true
        }else{
            return false
        }
    }
    
}

export default PersistentStorageWithPolicy