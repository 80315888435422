import React from "react"
import ContextStore from "../stores/context.store"
import { Provider } from "mobx-react"
import querystring from "querystring"
import isMobile from "is-mobile"
import CookieManagerStore from "../stores/cookie-manager.store"
import ReviewsStore from "../stores/reviews.store"
import * as ReviewsSVC from "../services/reviews"

class Context extends React.Component {

    constructor(props) {
        super(props)
        this.stores = {
            context: new ContextStore(),
            cookie_manager: new CookieManagerStore(),
            reviews: new ReviewsStore(ReviewsSVC)
        }
    }

    componentDidMount() {
        const params = querystring.parse(this.props.location.search.replace("?", ""))
        if (params.v) {
            setTimeout(() => {
                this.stores.context.setVersion(params.v)
            })
        }
        this.stores.context.setDesktop(!isMobile({ tablet: true }))
        this.stores.cookie_manager.init()
        this.stores.reviews.init()
    }

    render() {
        const { children } = this.props
        return (
            <Provider
                context_store={this.stores.context}
                cookie_manager_store={this.stores.cookie_manager}
                reviews_store={this.stores.reviews}
            >
                <>
                    {children}
                    <div id="modal-root" />
                </>
            </Provider>
        )
    }

}

export default Context
