const { observable, action } = require("mobx");

class ContextStore {
    @observable version = "1"

    @observable desktop = false

    @action.bound
    setVersion(v) {
        this.version = v
    }

    @action.bound
    setDesktop(flag) {
        this.desktop = flag
    }
}

export default ContextStore