import {observable, action, computed} from "mobx"
import PersistentStorageWithPolicy from "../utils/persistent-storage-with-policy";

class CookieManagerStore {

    @observable functionalEnabled = true
    @observable performanceEnabled = true
    @observable targetingEnabled = true
    @observable acknowledged = false

    @observable preferencesVisible = false


    @action.bound
    init(){
        const savedPolicy = this.storageSystem.timeoutGet("trm_cs", "necessary")
        if (savedPolicy){
            this.acknowledged = true
            this.functionalEnabled = savedPolicy.functional
            this.performanceEnabled = savedPolicy.performance
            this.targetingEnabled = savedPolicy.targeting
        }else{
            this.acknowledged = false
        }
    }

    @action.bound
    showPreferences() {
      this.preferencesVisible = true
    }

    @action.bound
    showDisclaimer() {
      this.preferencesVisible = false
    }

    dispose(){

    }

    @action.bound
    save(){
        this.storageSystem.timeoutSet("trm_cs", this.policy, 365, "necessary")
        this.acknowledged = true
    }

    @action.bound
    reset(){
        this.storageSystem.remove("trm_cs", "necessary")
        this.acknowledged = false
    }

    @action.bound
    setFunctionalPolicy(flag){
        this.functionalEnabled = flag
        if (!flag){
            this.clearFunctionalCookies()
        }
    }

    @action.bound
    clearFunctionalCookies(){
        //clears all foodaciously functional cookies
        this.storageSystem.removeAll("functional")
    }

    @action.bound
    setPerformancePolicy(flag){
        this.performanceEnabled = flag
        if (!flag){
            this.clearPerformanceCookies()
        }
    }

    @action.bound
    clearPerformanceCookies(){
        //clears all foodaciously performance cookies
        this.storageSystem.removeAll("performance")
    }

    @action.bound
    setTargetingPolicy(flag){
        this.targetingEnabled = flag
        if (!flag){
            this.clearTargetingCookies()
        }
    }

    @action.bound
    clearTargetingCookies(){
        //clears all foodaciously targeting cookies
        this.storageSystem.removeAll("targeting")
    }

    @computed get policy(){
        return {
            functional: this.functionalEnabled,
            performance: this.performanceEnabled,
            targeting: this.targetingEnabled,
            necessary: true //always enabled
        }
    }

    @computed get storageSystem(){
        return new PersistentStorageWithPolicy(this.policy)
    }

}

export default CookieManagerStore