import { api } from "../utils/url"

const RATING_MAP = {
    "ONE": 1,
    "TWO": 2,
    "THREE": 3,
    "FOUR": 4,
    "FIVE": 5
}

export const getLastReviews = () => {
    return fetch(api(`/reviews`), { method: "GET" })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else {
                throw new Error("Failed fetching Google Reviews")
            }
        })
        .then(jsn => jsn.data)
        .then(data => ({
            average: data.averageRating,
            count: data.totalReviewCount,
            last_reviews: data.reviews.map(r => {
                const { reviewId, reviewer, starRating, comment, createTime } = r
                return {
                    reviewId,
                    reviewer,
                    rating: RATING_MAP[starRating],
                    comment,
                    createTime
                }
            })
        }))
}